<script>
import {list, deleteParceiro} from '@/domain/parceiros/services'
import {UTable, UTh, UTr, UTd, UCheckbox, date, LocalStorage} from 'uloc-vue'
import {
  EBtnTableOptions
} from 'uloc-vue-plugin-erp'
import MenuOptions from '../../layout/context-menu/context-window-options'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import listOptions from '../windows/listOptions'
import {datePtToEn} from '@/utils/date'
import tableColumnsCache from "@/utils/tableColumnsCache"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import DateInput from "@/reuse/input/Date"
import TaskListMenu from "@/components/sltarefas/components/lista/list-menu"
import * as STATUS from '@/domain/parceiros/helpers/types'
import {donwloadFile} from "@/utils/downloadFile"

const listName = 'sl.parceiros.lista'
const listStorage = tableColumnsCache(listName, [
  {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 1},
  {label: 'Código', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'Recebe Comissão', name: 'comissao', active: true, sortable: true, ordering: 1},
  {label: 'E-mail', name: 'email', active: true, sortable: true, ordering: 1},
  {label: 'Telefone', name: 'telefone', active: true, sortable: true, ordering: 1},
  {label: 'API', name: 'api', active: true, sortable: true, ordering: 1},
  {label: 'ERP', name: 'erp', active: true, sortable: true, ordering: 1},
  {label: 'PAINEL', name: 'painel', active: true, sortable: true, ordering: 1},
  {label: 'Status', name: 'active', active: true, sortable: true, ordering: 1},
], 4)

let filters = {
  id: null,
  search: null,
  data1: null,
  data2: null,
  tipoData: 'createdAt',
}

export default {
  name: 'Parceiros',
  provide: function () {
    return {
      container: this
    }
  },
  props: {
    parseProps: {required: false}
  },
  mixins: [DefaultMixin],
  components: {
    TaskListMenu,
    DateInput,
    ECol,
    ERow,
    MenuOptions,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    ErpSField
  },
  data() {
    const filtros = JSON.parse(JSON.stringify(filters))
    filtros.tipoData = 'createdAt'
    return {
      listStorage: listStorage,
      listType: 'active',
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: filtros,
        selected: [],
        loading: false
      },
      stats: null,
      counts: null
    }
  },
  computed: {
    Status () {
      return STATUS
    },
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    isEmbedComponent () {
      return this.parseProps && this.parseProps.embedComponent
    }
  },
  beforeMount () {
    if (this.$route.hash) {
      this.parseHash()
    }
  },
  watch: {
    '$route' () {
      this.$nextTick(() => {
        if (this.parseHash()) {
          this.load()
        }
      })
    }
  },
  mounted() {
    this.load()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    parseHash () {
      if (this.$route.hash) {
        const hash = this.$route.hash.replace('#', '')
        this.parseStatusFilter()
        return true
      }
    },
    parseStatusFilter (hash) {
      if (hash === 'ativos' || hash === 'a') {
        this.table.filters.status = [0, 1]
        this.listType = 'active'
        //this.load()
      }
      if (hash === 'inativos' || hash === 'f') {
        this.table.filters.status = [99, 100]
        this.listType = 'inactive'
        //this.load()
      }
    },
    request({pagination, filter}, exportar = null) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || this.table.filters.data2.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.search && extraFilters.push(`&search=${this.table.filters.search}`)

      if (exportar) {
        extraFilters.push(`&export=${exportar}`)
      }

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}&${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, exportar)
          .then((response) => {
            if (exportar) {
              donwloadFile(response)
              this.table.loading = false
              return
            }
            const data = response.data
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result

            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    clearFilters() {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo(t = null) {
      this.$router.push({name: 'parceiros.new'})
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.descricao || this.table.busca
      })
    },
    abrir(id) {
      let router = this.$router || this.router
      let routeData = router.resolve({name: 'parceiros.show', params: {id: id}})
      window.open(routeData.href, '_blank')
    },
    edit(id) {
      this.$router.push({name: 'parceiros.show', params: {id: id}})
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este registro? Todos os dados serão perdidos.', 'excluir parceiro-' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteParceiro(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Parceiro excluído com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(listName, newConfig)
      this.listStorage = newConfig
    },
    changeListType (v) {
      this.listType = v
      this.parseStatusFilter(v)
      this.load()
    },
    label (a, b) {
      return a
    },
    exportar (tipo) {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      }, 'excel')
    },
  },
  meta: {
    title: 'Parceiros',
    name: 'Parceiros'
  }
}
</script>

<template>
    <div class="parceiro wrapper-md">
      <e-row class="m-b-lg">
        <e-col style="max-width: 350px">

        </e-col>
        <e-col class="flex items-center justify-center col-grow-1">
        </e-col>
        <e-col class="justify-end flex">
          <u-btn @click="novo" class="sl-btn" icon="plus" icon-type="fa" color="positive" no-caps label="Novo Parceiro"/>
        </e-col>
      </e-row>
      <div class="erp-list list-tasks no-pad">
        <div class="erp-list-top-btns items-end">
          <div class="actives-btns col-grow-1">
            <u-btn @click="changeListType('active')" :label="label('Ativos', 'active')" class="active-table-btn" :class="{active: listType === 'active'}" no-caps />
            <u-btn @click="changeListType('inactive')" :label="label('Inativos', 'inactive')" class="active-table-btn" :class="{active: listType === 'inactive'}" no-caps />
          </div>
          <div class="end font-14 bg-white flex-zero flex no-wrap">
              <e-col style="min-width: 170px" class="m-l m-r">
                <erp-s-field
                    view="ll"
                    label="Buscar"
                >
                  <erp-input shortkey="F3" v-model="table.filters.search" @keydown.enter="pesquisar"/>
                </erp-s-field>
              </e-col>
              <e-col style="min-width: 150px" class="m-r">
                <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (De)" v-model="table.filters.data1"/>
              </e-col>
              <e-col style="min-width: 150px" class="m-r">
                <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (Até)" v-model="table.filters.data2"/>
              </e-col>
            <e-col style="min-width: 100px">
              <u-btn @click="exportar('excel')" flat icon="file-excel" icon-type="fa" icon-style="light" title="Exportar para excel" label="Exportar" size="sm" no-caps />
            </e-col>
          </div>
        </div>
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table erp-table-options table-fin"
            :rows-per-page-options="[20, 50, 100]"
            :hide-no-results-label="true"
        >
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th v-for="col in props.cols" :key="col.name" :props="props">
              <template>
               {{ col.label }}
              </template>
              <div v-if="col.name === 'options'" class="text-center">
                <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
              </div>
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props" :class="{'u-table-item-destac': props.row.isToday}">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs"/>
            </u-td>
            <u-td class="td-limit" key="nome" :props="props">
              <div v-if="props.row.pessoa">
                {{ props.row.pessoa.name }}
              </div>
              <span v-else>-</span>
            </u-td>
            <u-td style="max-width: 40px; min-width: 40px; width: 40px" key="id" :props="props">
              {{ props.row.id }}
            </u-td>
            <u-td class="" key="comissao" :props="props" style="max-width: 50px; width: 50px">
              {{props.row.comissao ? 'Sim' : 'Não'}}
            </u-td>
            <u-td class="td-limit" key="email" :props="props">
              <div v-if="props.row.pessoa && props.row.pessoa.emails">
                {{ props.row.pessoa.emails[0].email }}
              </div>
              <span v-else>-</span>
            </u-td>
            <u-td class="td-limit" key="telefone" :props="props">
              <div v-if="props.row.pessoa && props.row.pessoa.phoneNumbers">
                {{ props.row.pessoa.emails[0].phoneNumber }}
              </div>
              <span v-else>-</span>
            </u-td>
            <td key="api">
              <svg v-if="props.row.api" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="17"
                   viewBox="0 0 16 17">
                <image id="Imagem_50" data-name="Imagem 50" width="16" height="17"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABQmlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSCwoyGFhYGDIzSspCnJ3UoiIjFJgf8bAxiDFIMRgySCWmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsgslf74DR2bZtj9meX1JPjbtTWY6lEAV0pqcTKQ/gPESckFRSUMDIwJQLZyeUkBiN0CZIsUAR0FZM8AsdMhbJC5IkkQ9gGwmpAgZyD7CpAtkJyRmAJkPwGydZKQxNOR2FB7QYAj2Mjc1dTQgIBTSQclqRUlINo5v6CyKDM9o0TBERhCqQqeecl6OgpGBkZGDAyg8Iao/nwDHI6MYhwIsdQdDAwmzUDBmwix7HcMDHsWMTDwvUOIqeoD+bcZGA6lFSQWJcIdwPiNpTjN2AjC5t7OwMA67f//z+EMDOyaDAx/r////3v7//9/lzEwMN9iYDjwDQBW5WGWUflcbwAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAEKADAAQAAAABAAAAEQAAAAAq/Xp4AAAAmUlEQVQ4EWP8DwQMFAAmCvSCtQ4xA/YdOsYgoaTP4BUUC/c50V4AaY5KyIRrhDNAsUAI7D149L+4oh4YewbGoChnQOFh4eDTDFION6Chrf8/CCMDQppRDIA5EWYIMZpRDABphBkC8icyG9lV6Gy4F0ASyIaADEAPMHTNID6KASABmCEwr4DE8AFGkCQ8TslgEJ2QcJk98AYAAD36g9yhConAAAAAAElFTkSuQmCC"/>
              </svg>
            </td>
            <td key="erp">
              <svg v-if="props.row.erp" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="17"
                   viewBox="0 0 16 17">
                <image id="Imagem_50" data-name="Imagem 50" width="16" height="17"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABQmlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSCwoyGFhYGDIzSspCnJ3UoiIjFJgf8bAxiDFIMRgySCWmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsgslf74DR2bZtj9meX1JPjbtTWY6lEAV0pqcTKQ/gPESckFRSUMDIwJQLZyeUkBiN0CZIsUAR0FZM8AsdMhbJC5IkkQ9gGwmpAgZyD7CpAtkJyRmAJkPwGydZKQxNOR2FB7QYAj2Mjc1dTQgIBTSQclqRUlINo5v6CyKDM9o0TBERhCqQqeecl6OgpGBkZGDAyg8Iao/nwDHI6MYhwIsdQdDAwmzUDBmwix7HcMDHsWMTDwvUOIqeoD+bcZGA6lFSQWJcIdwPiNpTjN2AjC5t7OwMA67f//z+EMDOyaDAx/r////3v7//9/lzEwMN9iYDjwDQBW5WGWUflcbwAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAEKADAAQAAAABAAAAEQAAAAAq/Xp4AAAAmUlEQVQ4EWP8DwQMFAAmCvSCtQ4xA/YdOsYgoaTP4BUUC/c50V4AaY5KyIRrhDNAsUAI7D149L+4oh4YewbGoChnQOFh4eDTDFION6Chrf8/CCMDQppRDIA5EWYIMZpRDABphBkC8icyG9lV6Gy4F0ASyIaADEAPMHTNID6KASABmCEwr4DE8AFGkCQ8TslgEJ2QcJk98AYAAD36g9yhConAAAAAAElFTkSuQmCC"/>
              </svg>
            </td>
            <td key="painel">
              <svg v-if="props.row.painel" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="17"
                   viewBox="0 0 16 17">
                <image id="Imagem_50" data-name="Imagem 50" width="16" height="17"
                       xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAARCAYAAADUryzEAAABQmlDQ1BJQ0MgUHJvZmlsZQAAKJFjYGASSCwoyGFhYGDIzSspCnJ3UoiIjFJgf8bAxiDFIMRgySCWmFxc4BgQ4ANUwgCjUcG3awyMIPqyLsgslf74DR2bZtj9meX1JPjbtTWY6lEAV0pqcTKQ/gPESckFRSUMDIwJQLZyeUkBiN0CZIsUAR0FZM8AsdMhbJC5IkkQ9gGwmpAgZyD7CpAtkJyRmAJkPwGydZKQxNOR2FB7QYAj2Mjc1dTQgIBTSQclqRUlINo5v6CyKDM9o0TBERhCqQqeecl6OgpGBkZGDAyg8Iao/nwDHI6MYhwIsdQdDAwmzUDBmwix7HcMDHsWMTDwvUOIqeoD+bcZGA6lFSQWJcIdwPiNpTjN2AjC5t7OwMA67f//z+EMDOyaDAx/r////3v7//9/lzEwMN9iYDjwDQBW5WGWUflcbwAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAEKADAAQAAAABAAAAEQAAAAAq/Xp4AAAAmUlEQVQ4EWP8DwQMFAAmCvSCtQ4xA/YdOsYgoaTP4BUUC/c50V4AaY5KyIRrhDNAsUAI7D149L+4oh4YewbGoChnQOFh4eDTDFION6Chrf8/CCMDQppRDIA5EWYIMZpRDABphBkC8icyG9lV6Gy4F0ASyIaADEAPMHTNID6KASABmCEwr4DE8AFGkCQ8TslgEJ2QcJk98AYAAD36g9yhConAAAAAAElFTkSuQmCC"/>
              </svg>
            </td>
            <u-td class="" key="active" :props="props" style="max-width: 50px; width: 50px">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                <rect id="Retângulo_1011" data-name="Retângulo 1011" width="12" height="12" rx="6"
                      :fill="props.row.active ? '#3baf55' : '#EB0000'"/>
              </svg>
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <task-list-menu @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                                     @excluir="excluir(props.row.id)" :leilao="props.row" />
                </menu-options>
              </e-btn-table-options>
            </u-td>
          </u-tr>
        </u-table>

        <!--<div class="flex justify-between">
          <div class="m-t">
            <u-btn icon="print" color="blue-grey-6" flat round no-caps>
              <u-tooltip :offset="[5,5]">Imprimir</u-tooltip>
            </u-btn>
            <u-btn icon="file_upload" color="blue-grey-6" flat round no-caps>
              <u-tooltip :offset="[5,5]">Exportar</u-tooltip>
            </u-btn>
          </div>
          <div>

          </div>
        </div>-->
      </div>
    </div>
</template>
